import { render, staticRenderFns } from "./BrandCheckbox.pug?vue&type=template&id=68a0c4ad&lang=pug&"
import script from "./BrandCheckbox.js?vue&type=script&lang=js&"
export * from "./BrandCheckbox.js?vue&type=script&lang=js&"
import style0 from "./BrandCheckbox.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports