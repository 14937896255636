export default {
  name: 'BrandButton',
  props: {
    title: {
      default: '',
    },
    theme: {
      default: '',
    },
    type: {
      default: 'button',
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      selfDisabled: this.disabled,
    };
  },
  computed: {
    getMods() {
      let mods = '';

      if (this.theme.length) {
        mods += `brand-button_theme-${this.theme}`;
      }
      return mods;
    },
    setType() {
      return `${this.type}`;
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
  watch: {
    disabled(state) {
      this.selfDisabled = state;
    },
  },
};
