import BrandSvg from '../BrandSvg/BrandSvg.vue';

export default {
  name: 'BrandCheckbox',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: 'Это чекбокс',
    },
    value: '',
    theme: {
      default: '',
    },
    checked: Boolean,
  },
  components: {
    BrandSvg,
  },
  data() {
    return {
      isDisabled: false,
    };
  },
  computed: {
    getMods() {
      let mods = '';

      if (this.theme.length) {
        mods += `brand-checkbox_theme-${this.theme}`;
      }
      return mods;
    },
  },
  methods: {
    onChange(e) {
      this.$emit('change', e.target.checked);
    },
  },
};
