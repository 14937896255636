import { mapGetters, mapActions } from 'vuex';
import BrandForm from '../BrandForm/BrandForm.vue';
import BrandInput from '../BrandInput/BrandInput.vue';
import BrandButton from '../BrandButton/BrandButton.vue';
import BrandSvg from '../BrandSvg/BrandSvg.vue';

export default {
  name: 'BrandModals',
  components: {
    BrandForm,
    BrandInput,
    BrandSvg,
    BrandButton,
  },
  methods: {
    ...mapActions([
      'addOrder',
      'updateTotal',
      'removeOrders',
    ]),
    closeModal() {
      this.$modal.hide('brand-modal');
    },
    addItem(id, target, name, title, price, picture) {
      const order = this.orders.items.find(item => item.name === target);
      if (typeof order !== 'undefined') {
        this.updateTotal(-order.price);
        this.removeOrders([target]);
      }
      this.addOrder({
        id,
        name: target,
        title: `${name} "${title}"`,
        count: 1,
        price,
      });
      this.updateTotal(price);
      this.$store.commit('setModalOutput', {
        name: target,
        picture,
        price,
      });
      this.closeModal();
    },
  },
  computed: mapGetters({
    target: 'getModalTarget',
    data: 'getModalData',
    orders: 'getOrders',
    isFormBusy: 'isFormBusy',
  }),
};
