import { mapActions, mapGetters } from 'vuex';
import api from '../../api/index';

export default {
  name: 'BrandForm',
  props: {
    titleSuccess: {
      type: String,
      default: '',
    },
    titleError: {
      type: String,
      default: '',
    },
    requestTarget: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formValidState: null,
    };
  },
  computed: {
    ...mapGetters({
      orders: 'getOrders',
    }),
  },
  methods: {
    ...mapActions([
      'setFormState',
    ]),
    validateBeforeSubmit() {
      this.$validator.validateAll()
        .then((result) => {
          if (result) {
            // Create fields object
            this.$store.commit('setFormBusy', true);
            const dataArray = {};
            if (this.requestTarget === 'call') {
              this.$validator.fields.items.forEach((field) => {
                if (field.name == 'phone') {
                  dataArray[field.name] = field.value.replace(/\s|[()-]/g, '').replace(/\D/g, '').substring(0, 11);
                } else dataArray[field.name] = field.value;
              });
            } else if (this.requestTarget === 'order/create') {
              const positions = [];
              this.$validator.fields.items.forEach((field) => {
                if (field.name == 'client_phone') {
                  dataArray[field.name] = field.value.replace(/\s|[()-]/g, '').replace(/\D/g, '').substring(0, 11);
                } else dataArray[field.name] = field.value;
              });
              this.orders.selected.forEach((field) => {
                dataArray[field.name] = field.value;
              });
              this.orders.items.forEach((field) => {
                if (field.type === 'select') {
                  dataArray[field.name] = field.value;
                } else if (field.name === 'podpyatniks' || field.name === 'nameplates') {
                  positions.push({
                    name: field.name,
                    count: field.count,
                    id: field.id,
                  });
                } else {
                  positions.push({
                    name: field.name,
                    count: field.count,
                    type: typeof field.type !== 'undefined' ? field.type : null,
                  });
                }
              });
              dataArray.positions = positions;
            }
            console.log(dataArray);
            // eslint-disable-next-line
            const data = api.getFormResponse(this.requestTarget, { json: JSON.stringify(dataArray) });
            data.then((response) => {
              // console.log('JSON_RESPONSE', response);
              // console.log('JSON_DATA', response.data);
              // console.log('JSON_SUCCESS', response.data.success);
              if (response.data.success) {
                this.setFormValidState(true);
                if (this.requestTarget === 'call') {
                  try {
                    // yaCounter12157180.reachGoal("gettoknow");
                    ym(53232403, 'reachGoal', 'zvonok');
                  } catch (e) {
                    console.warn('no metrics');
                  }
                } else if (this.requestTarget === 'order/create') {
                  try {
                    // yaCounter12157180.reachGoal("gettoknow");
                    ym(53232403, 'reachGoal', 'zakaz');
                  } catch (e) {
                    console.warn('no metrics');
                  }
                }
              } else {
                this.setFormValidState(false);
              }
              this.$store.commit('setFormBusy', false);
            })
              .catch((error) => {
                console.error(error);
                this.setFormValidState(false);
                this.$store.commit('setFormBusy', false);
              });
            return;
          }
          if (!this.$store.state.formValidated) {
            this.setFormState();
          }
          // console.log('NOT SUBMITTED');
        });
    },
    setFormValidState(state) {
      this.formValidState = state;
    },
  },
};
