import BrandSvg from '../BrandSvg/BrandSvg.vue';

export default {
  name: 'BrandRadio',
  props: {
    label: {
      type: String,
      default: 'Это радиокнопка',
    },
    value: '',
    name: '',
    theme: {
      default: '',
    },
  },
  components: {
    BrandSvg,
  },
  data() {
    return {
      isDisabled: false,
    };
  },
  computed: {
    getMods() {
      let mods = '';

      if (this.theme.length) {
        mods += `brand-radio_theme-${this.theme}`;
      }
      return mods;
    },
    radioButtonValue: {
      get() {
        return this.value;
      },
      set() {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit('change', this.label);
      },
    },
  },
  // methods: {
  //   onChange(e) {
  //     this.$emit('change', e.target.checked);
  //   },
  // },
};
