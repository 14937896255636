export default {
  name: 'BrandTextarea',
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {},
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      selfValue: this.value,
      focused: false,
      filled: false,
    };
  },
  mounted() {
    this.$watch('value', (value) => {
      this.selfValue = value;
    });
    this.filled = !!this.selfValue;
  },
  methods: {
    onInput() {
      this.$emit('input', this.selfValue);
      this.filled = !!this.selfValue;
    },
    onFocus() {
      this.$emit('focus');
      this.focused = true;
    },
    onBlur() {
      this.$emit('blur', this.selfValue);
      this.focused = false;
    },
  },
};
