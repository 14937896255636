import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex';
import api from '@/api/index';
import Heading from '../Heading/Heading.vue';
import BrandButton from '../BrandButton/BrandButton.vue';
import BrandCheckbox from '../BrandCheckbox/BrandCheckbox.vue';
import BrandRadio from '../BrandRadio/BrandRadio.vue';
import BrandInput from '../BrandInput/BrandInput.vue';
import BrandTextarea from '../BrandTextarea/BrandTextarea.vue';
import ItemCounter from '../ItemCounter/ItemCounter.vue';
import Collapse from '../Collapse/Collapse.vue';
import Chooser from '../Chooser/Chooser.vue';
import BrandSvg from '../BrandSvg/BrandSvg.vue';
import BrandSelect from '../BrandSelect/BrandSelect.vue';

export default {
  name: 'Equipment',
  components: {
    Heading,
    BrandButton,
    BrandCheckbox,
    BrandRadio,
    BrandSelect,
    ItemCounter,
    Collapse,
    Chooser,
    BrandSvg,
    BrandInput,
    BrandTextarea,
  },
  validations() {
    return {
      order: { required: requiredIf(() => this.isEmptyOrder) },
      carpet: { required: requiredIf(() => this.isCarpet)  },
      edging: { required: requiredIf(() => this.isCarpet) },
      deliveryAddress: { required: requiredIf(() => this.isDelivery) },
      selectedValue: { required },
    };
  },
  data() {
    return {
      inactiveTime: 0.66,
      tick: 0,
      kit: false,
      frontLeft: false,
      fullBack: false,
      frontRight: false,
      trunk: false,
      thirdRow: false,
      showThirdRow: true,
      deliveryByCourier: false,
      disableKit: false,
      counts: {
        kit: {count: 1, event: ''},
        frontLeft: {count: 1, event: ''},
        frontRight: {count: 1, event: ''},
        fullBack: {count: 1, event: ''},
        trunk: {count: 1, event: ''},
        thirdRow: {count: 1, event: ''},
        podpyatniks: {count: 0, event: ''},
        nameplates: {count: 0, event: ''},
      },
      labels: {
        kit: 'Комплект в салон 1 и 2 ряд',
        frontLeft: 'Водительский коврик',
        frontRight: 'Пассажирский коврик',
        fullBack: 'Задние коврики с перемычкой',
        trunk: 'Коврик в багажник',
        thirdRow: '3-й ряд сидений',
      },
      openDetail: false,
      selectedValue: null,
      carpet: '',
      edging: '',
      gift: false,
      podpyatnikImg: '',
      podpyatniks: false,
      nameplates: [0, 0, 0, 0, 0, 0],
      nameplateImg: '',
      nameplateMobile: false,
      calc: {},
      makes: '',
      makesLoading: false,
      models: '',
      model: null,
      modelsLoading: false,
      makesOptions: [],
      modelsOptions: [],
      deliveryAddress: '',
      submitStatus: null,
    };
  },
  methods: {
    ...mapActions([
      'setExploreState',
      'addOrder',
      'removeOrders',
      'updateTotal',
      'addSelected',
      'removeSelected',
      'updateCount',
      'resetOrder',
      'setDefaultItems',
      'setTotal',
      'setCarpetsPhotos'
    ]),
    
    async setDefaultOrder() {
      let kitPrice = 0;
      let frontRightPrice = 0;
      let frontLeftPrice = 0;
      let fullBackPrice = 0;
      let thirdRowPrice = 0;
      let podpyatniksprice = 0;
      let nameplates = 0;
      let carperPrice = 0;
      let deliveryPrice = 0;
      let fullPrice = 0;
      if (this.kit) {
        kitPrice = this.counts.kit.count * this.calc.prices.carpet.kit
      }

      if (this.frontRight) {
        frontRightPrice = this.counts.frontRight.count * this.calc.prices.carpet.frontRight
      }

      if (this.frontLeft) {
        frontLeftPrice = this.counts.frontLeft.count * this.calc.prices.carpet.frontLeft
      }

      if (this.fullBack) {
        fullBackPrice = this.counts.fullBack.count * this.calc.prices.carpet.back
      }

      if (this.thirdRow) {
        thirdRowPrice = this.counts.thirdRow.count * this.calc.prices.carpet.thirdRow
      }

      if (this.podpyatniks) {
        podpyatniksprice = this.counts.podpyatniks.count * this.calc.podpyatniks[0].price
      }

      if (this.nameplates) {
        nameplates = this.counts.nameplates.count * this.calc.nameplates[0].price
      }

      if (this.trunk) {
        carperPrice = this.counts.trunk.count * this.calc.prices.carpet.trunk
      }

      if (this.deliveryByCourier) {
        deliveryPrice = this.calc.prices.delivery.courierKrasnodar
      }

      fullPrice = 
        kitPrice
        + frontRightPrice
        + frontLeftPrice
        + fullBackPrice
        + thirdRowPrice
        + podpyatniksprice
        + nameplates
        + carperPrice
        + deliveryPrice;
      await this.setTotal(fullPrice);
    },

    resetOrdersHandler() {
      this.clearNameplates();
      this.clearPodpyatniks();
      this.counts = {
        kit: {count: 1, event: ''},
        frontLeft: {count: 1, event: ''},
        frontRight: {count: 1, event: ''},
        fullBack: {count: 1, event: ''},
        trunk: {count: 1, event: ''},
        thirdRow: {count: 1, event: ''},
        podpyatniks: {count: 0, event: ''},
        nameplates: {count: 0, event: ''},
      };
      this.kit = true;
      this.frontLeft = false;
      this.frontRight = false;
      this.fullBack = false;
      this.trunk = false;
      this.thirdRow = false;
    },

    clearInactive() {
      this.tick = 0;
    },

    tickInactive() {
      this.timer = setInterval(() => {
        this.tick++;
        if (this.tick >= (this.inactiveTime * 60)) {
          this.inactiveAction();
        }
      }, 1000);
    },

    stopTimer(e) {
      this.clearInactive();
      clearInterval(this.timer);
    },

    inactiveAction() {
      this.$store.commit('setModalTarget', 'request_call');
      this.$modal.show('brand-modal');
      this.stopTimer();
    },

    showModal() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        const container = document.querySelector('.equipment');
        const { y } = container.getBoundingClientRect();
        window.scrollTo(0, window.scrollY + y);
      } else {
        this.submitStatus = 'FORM'
        this.$store.commit('setModalTarget', 'request_order');
        this.$modal.show('brand-modal');
      }
    },
    changeValue(newValue) {
      this.selectedValue = newValue;
    },
    async getCalcData() {
      this.calc = await api.loadCalcData();
      if (this.calc.podpyatniks && this.calc.podpyatniks.length > 0) {
        this.podpyatnikImg = this.calc.podpyatniks[0].picture;
      }
      this.nameplateImg = this.calc.nameplates[0].picture;
      if (this.calc.inactiveTime) {
        this.inactiveTime = this.calc.inactiveTime;
      }
    },
    async getMakesData() {
      this.makesLoading = true;
      this.makesOptions = await api.loadMakes();
      this.makesLoading = false;
    },
    async getModelsData(id) {
      this.modelsLoading = true;
      this.modelsOptions = await api.loadModels(id);
      this.modelsLoading = false;
    },
    handleDeliveryAddress(value) {
      this.addOrder({
        type: 'select',
        name: 'delivery_address',
        value,
      });
    },
    handleComment(value) {
      this.addOrder({
        type: 'select',
        name: 'comment',
        value,
      });
    },
    handlePodpyatniks(data) {
      this.podpyatniks = this.updateOrder('podpyatniks', data);
      this.setDefaultOrder();
    },
    handleNameplates(data) {
      this.updateOrder('nameplates', data);
      this.updateNameplatesPos();
    },
    updateOrder(target, data) {
      const order = this.orders.items.find(item => item.name === target);
      if (typeof order !== 'undefined') {
        if (data.count > 0) {
          this.updateCount({
            name: target,
            count: data.count,
          });
          if (data.event === 'clear') {
            this.setDefaultOrder();
          } else {
            this.setDefaultOrder();
          }
          return true;
        }
        this.setDefaultOrder();
        this.removeOrders([target]);
        return false;
      }
      if (target === 'podpyatniks' || target === 'nameplates') {
        this.addOrder({
          id: this.calc[target][0].id,
          name: target,
          title: `${this.calc[target][0].type} "${this.calc[target][0].name}"`,
          count: 1,
          price: this.calc[target][0].price,
        });
        this.setDefaultOrder();
        return true;
      }
      return false;
    },
    clearPodpyatniks() {
      this.podpyatniks = false;
      this.updateCount({
        name: 'podpyatniks',
        count: 0,
      });
      this.podpyatniks = false;
      const order = this.orders.items.find(item => item.name === 'podpyatniks');
      if (order) {
        this.removeOrders(['podpyatniks']);
        this.setDefaultOrder();
      }
    },
    clearNameplates() {
      this.updateCount({
        name: 'nameplates',
        count: 0,
      });
      this.nameplateMobile = false;
      this.nameplates = [0, 0, 0, 0, 0, 0];
      const order = this.orders.items.find(item => item.name === 'nameplates');
      if (order) {
        this.setDefaultOrder();
        this.removeOrders(['nameplates']);
      }
    },
    updateNameplatesPos() {
      this.nameplates.fill(0);
      let nameplatesCount = this.counts.nameplates.count;
      if (nameplatesCount > 0) {
        this.nameplateMobile = true;
        if (this.kit) {
          switch (nameplatesCount) {
            case 1:
              this.nameplates[0] = 1;
              nameplatesCount -= 1;
              break;
            case 2:
              this.nameplates[0] = 1;
              this.nameplates[1] = 1;
              nameplatesCount -= 2;
              break;
            case 3:
              this.nameplates[0] = 1;
              this.nameplates[1] = 1;
              this.nameplates[3] = 1;
              nameplatesCount -= 3;
              break;
            case 4:
              this.nameplates[0] = 1;
              this.nameplates[1] = 1;
              this.nameplates[2] = 1;
              this.nameplates[4] = 1;
              nameplatesCount -= 4;
              break;
            default:
              this.nameplates[0] = 1;
              this.nameplates[1] = 1;
              this.nameplates[2] = 1;
              this.nameplates[3] = 1;
              this.nameplates[4] = 1;
              nameplatesCount -= 5;
          }
        } else {
          if (this.frontLeft && nameplatesCount > 0) {
            this.nameplates[0] = 1;
            nameplatesCount -= 1;
          }
          if (this.frontRight && nameplatesCount > 0) {
            this.nameplates[1] = 1;
            nameplatesCount -= 1;
          }
          if (this.fullBack && nameplatesCount > 0) {
            if (nameplatesCount === 1) {
              this.nameplates[3] = 1;
              nameplatesCount -= 1;
            } else if (nameplatesCount === 2) {
              this.nameplates[2] = 1;
              this.nameplates[4] = 1;
              nameplatesCount -= 2;
            } else {
              this.nameplates[2] = 1;
              this.nameplates[4] = 1;
              this.nameplates[3] = 1;
              nameplatesCount -= 3;
            }
          }
        }
        if (this.trunk && nameplatesCount > 0) {
          this.nameplates[5] = 1;
        }
      } else {
        this.nameplateMobile = false;
      }
    },
    async loadData() {
      await this.getCalcData();
      await this.getMakesData();
      this.kit = true;
      if (this.inactiveTime) {
        this.tickInactive();
      }
    }
  },
  computed: {
    ...mapGetters({
      orders: 'getOrders',
      total: 'getOrdersCount',
      modal: 'getModalOutput',
    }),
    isDelivery() {
      return this.selectedValue === 'Доставка курьером по Краснодару и регионам РФ';
    },
    isCarpet() {
      return !!this.kit || (!!this.frontLeft || !!this.frontRight || !!this.fullBack) || !!this.trunk || !!this.thirdRow;
    },
    isEmptyOrder() {
      const isNameplates = this.counts.nameplates.count > 0;
      const result = !!this.isCarpet || !!this.gift || !!this.podpyatniks || !!isNameplates;
      return !result;
    },
    isGiftVisible() {
      return this.kit || this.frontLeft || this.frontRight || this.fullBack || this.trunk;
    },
  },
  created() {
    this.loadData();
  },
  watch: {
    kit: {
      immediate: false,
      handler(status) {
        this.selectedValue = null;
        if (status) {
          this.frontLeft = false;
          this.frontRight = false;
          this.fullBack = false;
          this.removeOrders(['frontLeft', 'frontRight', 'back']);
          this.addOrder({
            name: 'kit',
            title: this.labels.kit,
            count: this.counts.kit.count,
          });
          this.setDefaultOrder();
        } else {
          this.removeOrders(['kit']);
          this.setDefaultOrder();
        }
        this.updateNameplatesPos();
        this.clearInactive();
      },
    },
    frontLeft(status) {
      this.selectedValue = null;
      if (status) {
        this.kit = false;
        this.removeOrders(['kit']);
        this.addOrder({
          name: 'frontLeft',
          title: this.labels.frontLeft,
          count: this.counts.frontLeft.count,
        });
        this.setDefaultOrder();
      } else {
        this.removeOrders(['frontLeft']);
        this.setDefaultOrder();
      }
      this.clearInactive();
      this.updateNameplatesPos();
    },
    frontRight(status) {
      this.selectedValue = null;
      if (status) {
        this.kit = false;
        this.removeOrders(['kit']);
        this.addOrder({
          name: 'frontRight',
          title: this.labels.frontRight,
          count: this.counts.frontRight.count,
        });
        this.setDefaultOrder();
      } else {
        this.removeOrders(['frontRight']);
        this.setDefaultOrder();
      }
      this.clearInactive();
      this.updateNameplatesPos();
    },
    fullBack(status) {
      this.selectedValue = null;
      if (status) {
        this.kit = false;
        this.removeOrders(['kit']);
        this.addOrder({
          name: 'back',
          title: this.labels.fullBack,
          count: this.counts.fullBack.count,
        });
        this.setDefaultOrder();
      } else {
        this.removeOrders(['back']);
        this.setDefaultOrder();
      }
      this.clearInactive();
      this.updateNameplatesPos();
    },
    trunk(status) {
      this.selectedValue = null;
      if (status) {
        this.addOrder({
          name: 'trunk',
          title: this.labels.trunk,
          count: this.counts.trunk.count,
        });
        this.setDefaultOrder();
      } else {
        this.removeOrders(['trunk']);
        this.setDefaultOrder();
      }
      this.clearInactive();
      this.updateNameplatesPos();
    },
    thirdRow(status) {
      this.selectedValue = null;
      if (status) {
        this.addOrder({
          name: 'thirdRow',
          title: this.labels.thirdRow,
          count: this.counts.thirdRow.count,
        });
        this.setDefaultOrder();
      } else {
        this.removeOrders(['thirdRow']);
        this.setDefaultOrder();
      }
      this.clearInactive();
      this.updateNameplatesPos();
    },
    selectedValue(value) {
      if (value === null) {
        this.removeOrders(['delivery']);
      } else {
        if (value === 'Самовывоз') {
          this.deliveryByCourier = false;
          if (typeof this.orders.items.find(item => item.value === 'courier') !== 'undefined') {
            this.setDefaultOrder();
            this.removeOrders(['delivery', 'delivery_address']);
          }
          this.addOrder({
            type: 'select',
            name: 'delivery',
            value: 'pickup',
            title: value,
            count: 1,
          });
          this.setDefaultOrder();
        } else {
          this.deliveryByCourier = true;
          if (typeof this.orders.items.find(item => item.value === 'pickup') !== 'undefined') {
            this.setDefaultOrder();
            this.removeOrders(['delivery']);
          }
          this.addOrder({
            type: 'select',
            name: 'delivery',
            value: 'courier',
            title: value,
            count: 1,
          });
          this.setDefaultOrder();
        }
      }
      this.clearInactive();
    },
    carpet(value) {
      this.selectedValue = null;
      if (value) {
        this.addSelected({
          name: 'color',
          value: value.label,
        });
      } else {
        this.removeSelected(['color']);
      }
      this.clearInactive();
    },
    edging(value) {
      this.selectedValue = null;
      if (value) {
        this.addSelected({
          name: 'border_color',
          value: value.label,
        });
      } else {
        this.removeSelected(['border_color']);
      }
      this.clearInactive();
    },
    makes(item) {
      this.modelsOptions = [];
      this.models = null;
      this.resetOrdersHandler();
      this.selectedValue = null;
      if (item) {
        this.getModelsData(item.value);
        this.addSelected({
          name: 'brand_auto',
          value: item.value,
        });
        if (item.customProperties) {
          if (!item.customProperties.nameplate_src) {
            this.clearNameplates();
          }
        }
      } else {
        this.clearNameplates();
        this.removeSelected(['brand_auto']);
      }
      this.setDefaultOrder();
      this.clearInactive();
    },
    models(item) {
      this.resetOrdersHandler();
      this.clearInactive();
      this.selectedValue = null;
      if (item) {
        this.kit = true;
        this.addSelected({
          name: 'model_auto',
          value: item.value,
        });
        if (item.customProperties.available.thirdRow) {
          this.showThirdRow = true;
          setTimeout(() => {
            this.thirdRow = true;
          }, 100);
        } else {
          this.thirdRow = false;
          setTimeout(() => {
            this.showThirdRow = false;
          }, 100);
        }
      } else {
        this.showThirdRow = true;
        this.removeSelected(['model_auto']);
      }
      if (item) {
        if (!item.customProperties.available.frontLeft ||
            !item.customProperties.available.frontRight ||
            !item.customProperties.available.back) {
              this.kit = false;
              this.disableKit = true;
              this.openDetail = true;
              this.frontLeft = item.customProperties.available.frontLeft;
              this.frontRight = item.customProperties.available.frontRight;
              this.fullBack = item.customProperties.available.back;
            } else {
              this.kit = true;
              this.disableKit = false;
              this.openDetail = false;
            }
      }
      this.setDefaultOrder();
      if (this.models) {
        const selectedModelPrices = this.modelsOptions.find(el => el.value === this.models.value);
        this.calc.prices.carpet = selectedModelPrices.customProperties.customPrices;
      }
    },
    modal(obj) {
      if (obj.name === 'podpyatniks') {
        this.podpyatnikImg = obj.picture;
        this.$refs.podpyatniksCounter.currentPrice = obj.price;
        this.$refs.podpyatniksCounter.count = 1;
        this.$refs.podpyatniksCounter.setDisableState(!obj.picture.length);
      } else {
        this.nameplateImg = obj.picture;
        this.$refs.nameplatesCounter.currentPrice = obj.price;
        this.$refs.nameplatesCounter.count = 1;
        this.$refs.nameplatesCounter.setDisableState(!obj.picture.length);
      }
    },
    isGiftVisible(value) {
      if (!value) {
        this.gift = false;
      }
    },
    gift(value) {
      if (value) {
        this.addOrder({
          name: 'packaging',
          type: 'gift',
          title: 'Подарочная упаковка',
          count: 1,
        });
        this.setDefaultOrder();
      } else {
        this.removeOrders(['packaging']);
        this.setDefaultOrder();
      }
      this.clearInactive();
    },
    'models.value'(value) {
      const model = this.modelsOptions.filter(el => el.value === value);
      const photos = model[0] ? model[0].photos : [];
      this.setCarpetsPhotos([]);
      setTimeout(() => {
        this.setCarpetsPhotos(photos);
      }, 0)
    }
  },
};
