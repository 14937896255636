import { mapGetters, mapActions } from 'vuex';
import BrandLink from '../BrandLink/BrandLink.vue';

export default {
  name: 'Menu',
  components: {
    BrandLink,
  },
  computed:
    mapGetters([
      'isExplored',
    ]),
  methods:
    mapActions([
      'setExploreState',
    ]),
};
