<template lang="pug">
  include ../../../node_modules/bempug/index
  - BEMPUG.modifier = '_';
  - const parent = 'brand-select';
  +b('brand-select-wrapper')(:class="{ 'brand-select-wrapper_loading': loading}")
    +b(parent, {t: 'select'})(ref="selectControl" v-bind="$attrs")
      +e('option', {t: 'option'})(v-for="option in updatedOptions" :key="option.value" :value="option[valueProp]" :customProperties="option.customProperties") {{option.label}}
    +e('loading', {t: 'spinner'})(v-if="loading")
</template>

<script>
import Choices from 'choices.js';
import Spinner from '../Spinner/Spinner.vue';

export default {
  name: 'BrandSelect',
  inheritAttrs: false,
  components: {
    Spinner
  },
  data() {
    return {
      control: null,
      choices: null,
    };
  },
  props: {
    options: {
      type: Array,
      default: [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  computed: {
    updatedOptions() {
      return [
        {
          label: this.placeholder,
          value: ''
        },
        ...this.options
      ]
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(options) {
        if (this.choices) {
          this.choices.setChoices(this.updatedOptions, this.valueProp || 'value', 'label', true,);
          this.choices.setChoiceByValue("");
        }
      },
    },
    value: {
      immediate: true,
      handler(options) {
        if (this.choices) {
          this.choices.setChoiceByValue("");
        }
      },
    },
  },
  mounted() {
    this.control = this.$refs.selectControl;
    if (this.control) {
      const pluginOptions = {
        loadingText: 'Загрузка...',
        noChoicesText: 'Ничего не найдено',
        itemSelectText: '',
        searchEnabled: false,
        shouldSort: false,
        shouldSortItems: false,
        placeholder: false,
        removeItems: false,
        classNames: {
          containerOuter: 'brand-select',
          containerInner: 'brand-select__inner',
          input: 'brand-select__input',
          inputCloned: 'brand-select__input--cloned',
          list: 'brand-select__list',
          listItems: 'brand-select__list--multiple',
          listSingle: 'brand-select__list--single',
          listDropdown: 'brand-select__list--dropdown',
          item: 'brand-select__item',
          itemSelectable: 'brand-select__item--selectable',
          itemDisabled: 'brand-select__item--disabled',
          itemChoice: 'brand-select__item--choice',
          placeholder: 'brand-select__placeholder',
          group: 'brand-select__group',
          groupHeading: 'brand-select__heading',
          button: 'brand-select__button',
        }
      }
      this.choices = new Choices(this.control, pluginOptions);
      this.choices.passedElement.element.addEventListener('choice', (choice) => {
        this.$emit('change', { ...choice.detail.choice });
      });
      if (this.choices) {
        this.choices.setChoices(this.options, this.valueProp || 'value', 'label', true,);
        this.choices.setChoiceByValue("");
      }
    }
  },
  beforeDestroy() {
    if (this.choices) {
      this.choices.destroy();
    }
  },
}
</script>

<style lang="scss">
  @import "./BrandSelect.scss";
  .brand-select {
    $root: &;

    &__inner {
      appearance: none;
      display: flex;
      padding: 0;
      background: none;
      white-space: normal;
      border-radius: 5px;
      border: 1px solid rgba(51,51,51,.2);
      transition: all .3s;
    }

    &__inner & {
      &__list--single {
        display: flex;
        align-items: center;
        #{$root}__item {
          padding: 8px 24px 8px 16px;
          white-space: nowrap;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    &__item {
      text-align: left;
    }

    &__list--dropdown & {
      &__list {
        width: 100%;
      }
      &__item {
        padding-left: 16px;
        padding-right: 10px;
        word-break: break-word;
      }
    }

    &__list--dropdown {
      box-shadow: 0 10px 30px 0 rgba(111,124,151,.2);
      border-radius: 5px;
      background: #fff;
      top: 110%;
      border: none;
      display: flex;
      align-items: center;

    }

    &__list--single & {
      &__item {
      width: 100%;
      text-align: left;
      }
    }

    &[data-type*='select-one'] & {
      &__inner {
        padding-bottom: 0;
      }
    }

    &[data-type*='select-one'] {
      &:after {
        border-color: #E61212 transparent transparent transparent;
      }
    }
  }

  .brand-select-wrapper {
    width: 100%;
    position: relative;
    flex: 1;
    margin: 0 15px;
    max-width: 320px;
    &__loading {
      position: absolute;
      right: 15px;
      top: 0;
      width: 20px;
      height: 100%;
    }

    &_loading {
      .brand-select[data-type*='select-one']:after {
        display: none;
      }
    }
  }

  .brand-select-wrapper + .brand-select-wrapper {
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }

  .is-open .brand-select__inner {
    border-radius: 5px;
  }
</style>
