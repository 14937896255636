import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isDOMReady: false,
    menuExplored: false,
    formValidated: false,
    isFormBusy: false,
    modalTarget: '',
    modalData: '',
    modalOutput: '',
    calcData: [],
    orders: {
      total: 0,
      items: [],
      selected: [],
    },
    carpetsPhotos: []
  },
  mutations: {
    resetOrder(state) {
      state.orders = {
        total: 0,
        items: [],
        selected: [],
      };
    },
    setExploreState(state) {
      // eslint-disable-next-line
      state.menuExplored = !state.menuExplored;
    },
    setFormState(state) {
      // eslint-disable-next-line
      state.formValidated = !state.formValidated;
    },
    setFormBusy(state, payload) {
      // eslint-disable-next-line
      state.isFormBusy = payload;
    },
    setDOMReady(state, value) {
      // eslint-disable-next-line
      state.isDOMReady = value;
    },
    setModalTarget(state, modal) {
      // eslint-disable-next-line
      state.modalTarget = modal;
    },
    setModalData(state, data) {
      // eslint-disable-next-line
      state.modalData = data;
    },
    setModalOutput(state, data) {
      // eslint-disable-next-line
      state.modalOutput = data;
    },
    setCalcDataItems(state, payload) {
      state.calcData = payload;
    },
    setBanners(state, payload) {
      state.banners = payload;
    },
    addOrder(state, order) {
      state.orders.items.push(order);
    },
    addSelected(state, selected) {
      state.orders.selected.push(selected);
    },
    removeOrders(state, orders) {
      for (let i = 0; i < state.orders.items.length; i += 1) {
        const obj = state.orders.items[i];
        if (orders.indexOf(obj.name) !== -1) {
          state.orders.items.splice(i, 1);
          i -= 1;
        }
      }
    },
    removeSelected(state, orders) {
      for (let i = 0; i < state.orders.selected.length; i += 1) {
        const obj = state.orders.selected[i];
        if (orders.indexOf(obj.name) !== -1) {
          state.orders.selected.splice(i, 1);
          i -= 1;
        }
      }
    },
    updateTotal(state, amount) {
      state.orders.total += amount;
    },
    setTotal(state, amount) {
      state.orders.total = amount;
    },
    setDefaultItems(state, value) {
      state.orders.items = [value];
    },
    updateCount(state, data) {
      for (let i = 0; i < state.orders.items.length; i += 1) {
        const obj = state.orders.items[i];
        if (obj.name === data.name) {
          state.orders.items[i].count = data.count;
        }
      }
    },
    setCarpetsPhotos(state, data) {
      state.carpetsPhotos = data;
    },
  },
  actions: {
    setExploreState() {
      if (window.innerWidth < 1440) {
        this.commit('setExploreState');
      }
    },
    resetOrder() {
      this.commit('resetOrder');
    },
    setFormState() {
      this.commit('setFormState');
    },
    addOrder({ commit }, order) {
      commit('addOrder', order);
    },
    addSelected({ commit }, selected) {
      commit('addSelected', selected);
    },
    removeOrders({ commit }, orders) {
      commit('removeOrders', orders);
    },
    removeSelected({ commit }, orders) {
      commit('removeSelected', orders);
    },
    updateTotal({ commit }, amount) {
      commit('updateTotal', amount);
    },
    setTotal({ commit }, amount) {
      commit('setTotal', amount);
    },
    setDefaultItems({ commit }, value) {
      commit('setDefaultItems', value);
    },
    updateCount({ commit }, data) {
      commit('updateCount', data);
    },
    scrollToSection(target, to, duration) {
      if (duration <= 0) return;
      const el = target;
      const difference = to - el.scrollTop;
      const perTick = difference / duration * 10;

      setTimeout(() => {
        el.scrollTop += perTick;
        if (el.scrollTop === to) return;
        this.scrollToSection(el, to, duration - 10);
      }, 10);
    },
    // async loadCalcDataItems({ commit }) {
    //   commit('setCalcDataItems', await api.loadCalcData());
    // },
    // async setBanners({ commit }) {
    //   commit('setBanners', await api.loadBanners());
    // },
    setCarpetsPhotos({ commit }, data) {
      commit('setCarpetsPhotos', data);
    }
  },
  getters: {
    isExplored: state => (state.menuExplored ? 'state_explored' : ''),
    isFormValidated: state => (state.formValidated),
    isFormBusy: state => (state.isFormBusy),
    getModalTarget: state => (state.modalTarget),
    getModalData: state => (state.modalData),
    getModalOutput: state => (state.modalOutput),
    // getCalcDataInfo: state => state.calcData,
    getOrders: state => state.orders,
    // getBanners: state => state.banners,
    getOrdersCount: state => state.orders.items,
    isDOMReady: state => state.isDOMReady,
    isCarpetsPhotos: state => !!state.carpetsPhotos.length ? true : false,
    getCarpetsPhotos: state => state.carpetsPhotos
  },
});
