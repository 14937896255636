// import { mapGetters } from 'vuex';
import api from '@/api/index';
import { mapGetters } from 'vuex';
import { swiper, swiperSlide } from 'vue-awesome-swiper/src';
import BrandButton from '../BrandButton/BrandButton.vue';
import BrandLink from '../BrandLink/BrandLink.vue';
import BrandSvg from '../BrandSvg/BrandSvg.vue';

export default {
  name: 'CarpetsCarousel',
  props: {},
  components: {
    swiper,
    swiperSlide,
    BrandButton,
    BrandSvg,
    BrandLink,
  },
  data() {
    return {
      swiperObj: null,
      swiperOptions: {
        direction: 'horizontal',
        slidesPerView: 1,
        grabCursor: true,
        loop: true,
        loopAdditionalSlides: 3,
        autoplay: {
          delay: 5000,
        },
        wrapperClass: 'carpets-carousel__wrapper',
        slideClass: 'carpets-carousel__slide',
        navigation: {
          nextEl: '.carpets-carousel__button-next',
          prevEl: '.carpets-carousel__button-prev',
        },
        pagination: {
          el: '.carpets-carousel__pagination',
          clickable: true,
          bulletClass: 'carpets-carousel__bullet',
          bulletActiveClass: 'state_active',
        },
      },
      banners: [],
      activeSlide: 0,
    };
  },
  computed: {
    ...mapGetters([
      'getCarpetsPhotos'
    ]),
  },
  methods: {
    showModal() {
      this.$store.commit('setModalTarget', 'request_order');
      this.$modal.show('brand-modal');
    },
    async getBanners() {
      this.banners = this.getCarpetsPhotos;
    },
  },
  mounted() {
    this.getBanners();
  }
};
