import Header from '../components/Header/Header.vue';
import Menu from '../components/Menu/Menu.vue';
import Footer from '../components/Footer/Footer.vue';
import Home from '../views/Home/Home.vue';
import BrandModals from '../components/BrandModals/BrandModals.vue';
import SocialWidget from '../components/SocialWidget/SocialWidget.vue';

export default {
  components: {
    Header,
    Footer,
    Menu,
    BrandModals,
    Home,
    SocialWidget,
  },
};
