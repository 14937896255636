import { render, staticRenderFns } from "./BrandButton.pug?vue&type=template&id=2bed789c&lang=pug&"
import script from "./BrandButton.js?vue&type=script&lang=js&"
export * from "./BrandButton.js?vue&type=script&lang=js&"
import style0 from "./BrandButton.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports