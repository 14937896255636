export default {
  name: 'Heading',
  props: {
    title: {
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {},
};
