import { mapGetters } from 'vuex';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import Heading from '../Heading/Heading.vue';
import BrandLink from '../BrandLink/BrandLink.vue';
import BrandSvg from '../BrandSvg/BrandSvg.vue';

export default {
  name: 'Map',
  components: {
    yandexMap,
    ymapMarker,
    Heading,
    BrandLink,
    BrandSvg,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      coords: [],
      controls: ['fullscreenControl', 'zoomControl'],
      behaviors: ['drag'],
      placemarks: [
        {
          coords: [45.02136736672282, 39.06955931349183],
          properties: {}, // define properties here
          options: {}, // define options here
          callbacks: {
            click() {
              console.log('CLICKED');
            },
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'isDOMReady',
    ]),
  },
  mounted() {
    this.setMapCenter();
    this.setWindowListener();
  },
  methods: {
    setMapCenter() {
      if (this.windowWidth >= 1440) {
        this.coords = [45.0219174759287, 39.06143191662558];
      } else if (this.windowWidth >= 1024) {
        this.coords = [45.02117939181868, 39.061641128928734];
      } else if (this.windowWidth >= 768) {
        this.coords = [45.021395632996395, 39.06412206950143];
      } else {
        this.coords = [45.02136736672282, 39.06955931349183];
      }
      return this.coords;
    },
    setWindowListener() {
      window.addEventListener('resize', this.getWindowWidth);
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    // eslint-disable-next-line
    windowWidth: function() {
      this.setMapCenter();
    },
  },
};
