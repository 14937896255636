import { mapGetters } from 'vuex';
import About from '@/components/About/About.vue';
import Features from '@/components/Features/Features.vue';
import BannerCarousel from '@/components/BannerCarousel/BannerCarousel.vue';
import CarpetsPhotosCarousel from '@/components/CarpetsPhotosCarousel/CarpetsPhotosCarousel.vue';
import Equipment from '@/components/Equipment/Equipment.vue';
import Map from '@/components/Map/Map.vue';

export default {
  name: 'Home',
  components: {
    BannerCarousel,
    CarpetsPhotosCarousel,
    About,
    Features,
    Equipment,
    Map,
  },
  computed: {
    ...mapGetters([
      'isExplored',
      'isCarpetsPhotos',
      'getCarpetsPhotos'
    ]),
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.$store.commit('setDOMReady', true);
        // this.initYandexMetrika(document, window, 'yandex_metrika_callbacks2');
      }
    };
  },
  methods: {
    initYandexMetrika(d, w, c) {
      // eslint-disable-next-line
      (w[c] = w[c] || []).push(() => {
        try {
          // eslint-disable-next-line
          w.yaCounter = new Ya.Metrika2({
            id: '53232403',
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          });
        } catch (e) {
          console.log('YA_ERROR', e);
        }
      });
      const n = d.getElementsByTagName('script')[0];
      const s = d.createElement('script');
      const f = () => {
        n.parentNode.insertBefore(s, n);
      };
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://mc.yandex.ru/metrika/tag.js';

      if (w.opera === '[object Opera]') {
        d.addEventListener('DOMContentLoaded', f, false);
      } else {
        f();
      }
    },
  },
};
