import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import VModal from 'vue-js-modal';
import VeeValidate from 'vee-validate';
import Inputmask from 'inputmask';
import Vuelidate from 'vuelidate'
import App from './app/App.vue';
import router from './router';
import store from './store';

// Styles assets
import './assets/styles/main.scss';
Vue.config.productionTip = false;


Vue.use(Vuelidate)
Vue.use(VueScrollTo, {
  duration: 600,
  offset: -80,
});

Vue.use(VModal, {
  componentName: 'brand-modal',
});

Vue.use(VeeValidate);
Vue.prototype.$inputmask = params => new Inputmask(params);


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
