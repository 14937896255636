export default class PhoneInputMask {
    constructor(input) {
        this.input = input;
        this.init();
    }

    init() {
        this.input.addEventListener('keydown', this.onPhoneKeyDown);
        this.input.addEventListener('input', this.onPhoneInput, false);
        this.input.addEventListener('paste', this.onPhonePaste, false);
        this.input.addEventListener('blur', this.onPhoneBlur);
        this.input.addEventListener('focus', this.onPhoneFocus);
    }

    onPhonePaste(e) {
      setTimeout(() => {
        function getInputNumbersValue(input) {
          return input.value.replace(/\D/g, '');
        }

        let input = e.target;
        let inputNumbersValue = getInputNumbersValue(input);
        let pasted = e.clipboardData || window.clipboardData;
        if (pasted) {
            let pastedText = pasted.getData('Text');
            if (/\D/g.test(pastedText)) {
                // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
                // formatting will be in onPhoneInput handler
                input.value = inputNumbersValue;
                return;
            }
        }
      }, 0);
        
    }

    onPhoneInput(e) {
      setTimeout(() => {
        function getInputNumbersValue(input) {
            return input.value.replace(/\D/g, '');
        }

        let input = e.target;
        let inputNumbersValue = getInputNumbersValue(input);
        let selectionStart = input.selectionStart;
        let formattedInputValue = "";

        if (!inputNumbersValue) {
            return input.value = "";
        }

        if (input.value.length != selectionStart) {
            // Editing in the middle of input, not last symbol
            if (e.data && /\D/g.test(e.data)) {
                // Attempt to input non-numeric symbol
                input.value = inputNumbersValue;
            }
            return;
        }

        if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] == "9") inputNumbersValue = "7" + inputNumbersValue;
            let firstSymbols = (inputNumbersValue[0] == "8") ? "8" : "+7";
            formattedInputValue = input.value = firstSymbols + " ";
            if (inputNumbersValue.length > 1) {
                formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
            }
        } else {
            formattedInputValue = '';
        }
        input.value = formattedInputValue;
        if (e.target.value.startsWith('+')) {
          	e.target.value = e.target.value.substring(0, 18);
        }
        if (e.target.value.startsWith('8')) {
          	e.target.value = e.target.value.substring(0, 17);
        }
      }, 0);
        
    }

    onPhoneKeyDown(e) {
		const ALLOWED_CHARS_REGEXP = /[0-9\/]+/;
		if (!ALLOWED_CHARS_REGEXP.test(e.key) && e.keyCode != 8) {
			console.log(e);
			e.preventDefault();
		}
        let inputValue = e.target.value.replace(/\D/g, '');
		if (e.keyCode != 8 && inputValue.length == 1) {
			e.target.value = "";
		}
    }

    onPhoneBlur(e) {
		if (e.target.value.startsWith('+')) {
			e.target.value = e.target.value.substring(0, 18);
		}
		if (e.target.value.startsWith('8')) {
			e.target.value = e.target.value.substring(0, 17);
		}
		if (e.target.value.length == 1) {
			e.target.value = '';
		}
    }

    onPhoneFocus(e) {
		if (e.target.value.startsWith('+')) {
			e.target.value = e.target.value.substring(0, 18);
		}
		if (e.target.value.startsWith('8')) {
			e.target.value = e.target.value.substring(0, 17);
		}
		if (e.target.value.length == 1) {
			e.target.value = '';
		}
    }
}