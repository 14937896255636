import BrandSvg from '../BrandSvg/BrandSvg.vue';

export default {
  name: 'BrandLink',
  componens: {
    BrandSvg,
  },
  props: {
    alt: '',
    title: {
      default: '',
    },
    theme: {
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    getMods() {
      let mods = '';

      if (this.theme.length) {
        mods += `brand-link_theme-${this.theme}`;
      }
      return mods;
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
