import { mapActions } from 'vuex';

export default {
  name: 'ItemCounter',
  inheritAttrs: false,
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      default: () => ({
        count: 0,
        event: '',
      }),
    },
    theme: {
      default: '',
    },
    price: 0,
    special: {
      type: String,
      default: '',
    },
    name: '',
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: this.special === '' ? 1 : 0,
      // count: this.special === '' ? 1 : 0,
      // total: 0,
      // isDisabled: false,
      currentPrice: this.price,
      valueChanged: false,
    };
  },
  computed: {
    isDisabled() {
      return this.disabled;
    },
    getMods() {
      let mods = '';

      if (this.theme.length) {
        mods += `item-counter_theme-${this.theme}`;
      }
      return mods;
    },
    total: {
      get() {
        return this.value * this.currentPrice;
      },
    },
    count: {
      get() {
        return this.model.count;
      }
    }
    // count: {
    //   get() {
    //     console.log('SPECIAL', this.special);
    //     return this.special === '' || typeof this.special === 'undefined' ? 1 : 0;
    //   },
    //   set() {},
    // },
  },
  methods: {
    ...mapActions([
      'updateTotal',
      'updateCount',
    ]),
    upCount() {
      if (!this.isDisabled) {
        this.value += 1;
        if (!this.special) {
          // this.calcTotal();
          this.updateCount({
            name: this.name,
            count: this.value,
          });
          this.updateTotal(this.currentPrice);
        }
        this.valueChanged = true;
        this.$emit('change', { count: this.value, event: 'up' });
      }
    },
    downCount() {
      if ((this.value > 1 || (this.value === 1 && this.special !== '')) && !this.isDisabled) {
        this.value -= 1;
        if (!this.special) {
          // this.calcTotal();
          this.updateCount({
            name: this.name,
            count: this.value,
          });
          this.updateTotal(-this.currentPrice);
        }
        this.$emit('change', { count: this.value, event: 'down' });
      }
    },
    getCount() {
      return this.value;
    },
  },
  watch: {
    price: {
      handler(value) {
        this.currentPrice = value;
      },
    },
    model: {
      handler(value) {
        if (value.count === 0 && this.valueChanged) {
          this.valueChanged = false;
        }
        this.value = value.count;
      },
    },
    name: {
      handler() {
        this.value = 1;
      },
    }
  },
};
