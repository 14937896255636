import axios from 'axios';
import jsonData from '../json/data.json';
import banners from '../json/banners.json';
import makes from '../json/makes.json';
import models from '../json/models.json';

const devUrl = process.env.VUE_APP_DEV_API_URL ? process.env.VUE_APP_DEV_API_URL : '/api';

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'development' || process.env.VUE_APP_DEV_API_URL ? devUrl  : 'https://евафокс.рф/api',
  headers: {
    'content-type': 'application/json',
  },
});

export default {
  http,
  getFormResponse(target, data) {
    return http.post(`/${target}`, data)
      .then(result => Promise.resolve(result))
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  },
  loadCalcData() {
    if (process.env.NODE_ENV === 'development') {
      return http.get('http://192.168.88.204:3005/api/get-data')
      .then(result => Promise.resolve(result.data))
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
    }
    return http.get('/calc/get-data')
      .then(result => Promise.resolve(result.data))
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  },
  loadBanners() {
    if (process.env.NODE_ENV === 'development') {
      return http.get('http://192.168.88.204:3005/api/get-all')
      .then(result => Promise.resolve(result.data))
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
    }
    return http.get('/slide/get-all')
      .then(result => Promise.resolve(result.data))
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  },
  loadMakes() {
    if (process.env.NODE_ENV === 'development') {
      return http.get('http://192.168.88.204:3005/api/brand')
      .then(result => Promise.resolve(result.data))
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
    }
    return http.get('/auto/brand')
      .then(result => Promise.resolve(result.data))
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  },
  loadModels(id) {
    if (process.env.NODE_ENV === 'development') {
      return http.get('http://192.168.88.204:3005/api/model')
      .then(result => Promise.resolve(result.data))
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
    }
    return http.get(`/auto/model/${id}`)
      .then(result => Promise.resolve(result.data))
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  },
};
