import { mapGetters, mapActions } from 'vuex';
import BrandButton from '../BrandButton/BrandButton.vue';
import BrandLink from '../BrandLink/BrandLink.vue';
import BrandSvg from '../BrandSvg/BrandSvg.vue';

export default {
  name: 'Header',
  components: {
    BrandButton,
    BrandSvg,
    BrandLink,
  },
  computed: mapGetters([
    'isExplored',
  ]),
  methods: {
    ...mapActions([
      'setExploreState',
    ]),
    showModal() {
      this.$store.commit('setModalTarget', 'request_call');
      this.$modal.show('brand-modal');
    },
    sendGoal() {
      try {
        // yaCounter12157180.reachGoal("gettoknow");
        ym(53232403, 'reachGoal', 'tel');
      } catch (e) {
        console.warn('no metrics');
      }
    },
  },
};
