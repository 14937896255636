import BrandSvg from '../BrandSvg/BrandSvg.vue';

export default {
  name: 'Collapse',
  components: {
    BrandSvg,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isExplored: false,
    };
  },
  methods: {
    onClick() {
      this.isExplored = !this.isExplored;
      this.setContentHeight();
    },
    setContentHeight() {
      this.$refs.content.parentElement.style.maxHeight = this.isExplored ? `${this.$refs.content.offsetHeight}px` : 0;
    },
  },
  watch: {
    open: {
      handler(value) {
        this.isExplored = value;
        this.setContentHeight();
      }
    }
  }
};
