import { parse } from 'postsvg';
import render from 'posthtml-render';

// Кэш загруженных иконок
const cache = new Map();

export default {
  name: 'BrandSvg',
  props: {
    // Имя файла (без расширения)
    iconName: {
      type: String,
      required: true,
    },
    // Ширина
    width: {
      type: [Number, String],
      default: 40,
    },
    // Высота
    height: {
      type: [Number, String],
      default: 40,
    },
    // Цвет заливки
    fill: {
      type: String,
      default: 'currentColor',
    },
    // Цвет обводки
    stroke: {
      type: String,
      default: 'none',
    },
    // Генерировать ли событие ready иконки
    generateReadyEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // Строка с содержимым SVG-файла
    return {
      svgString: '',
    };
  },
  computed: {
    // Путь расположения SVG-файла
    filepath() {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/symbols/${this.iconName}.svg`); // eslint-disable-line global-require
    },
    // Распарсенное дерево SVG-файла
    parsedSVG() {
      return this.svgString ? parse(this.svgString) : null;
    },
    // Размер холста
    viewBox() {
      return this.parsedSVG ? this.parsedSVG.root.attrs.viewBox : '0 0 40 40';
    },
  },
  watch: {
    // При изменении файла, перезагружаем его
    filepath: {
      immediate: true,
      handler: 'loadFile',
    },
    // При загрузке другого файла обновляем SVG
    svgString: 'refreshSvg',
    // При изменениях стилизации обновляем SVG
    stroke: 'refreshSvg',
    fill: 'refreshSvg',
  },
  methods: {
    // Загрузка содержимого SVG файла
    loadFile() {
      this.getSvgIconText()
      // eslint-disable-next-line no-return-assign
        .then(responseText => (this.svgString = responseText))
        .catch(error => console.error('Ошибка загрузки SVG-файла', error));
    },
    // Получение SVG-файла в виде текстовой строки
    // + кэширование ранее загруженных файлов
    async getSvgIconText() {
      const url = this.filepath;
      // Кэшируем загружаемые иконки
      // для сокращения запросов к серверу
      if (!cache.has(url)) {
        try {
          cache.set(url, fetch(url)
            .then(r => r.text()));
        } catch (e) {
          cache.delete(url);
        }
      }
      // Возвращаем содержимое из кэша
      try {
        return await cache.get(url);
      } catch (e) {
        return Promise.reject(new Error('Нет SVG-файла в локальном кэше'));
      }
      // return cache.has(url)
      // ? await cache.get(url)
      // : Promise.reject(new Error('Нет SVG-файла в локальном кэше'));
    },
    // Обновление SVG
    refreshSvg() {
      Promise.resolve(this.parsedSVG)
      // Обновляем стилизацию
        .then((svgTree) => {
          // eslint-disable-next-line no-return-assign
          svgTree.each('path', (node) => {
            if (node.attrs.class === 'apply-fill')
            // eslint-disable-next-line
            { node.attrs.fill = this.fill; }
            else if (node.attrs.class === 'apply-stroke')
            // eslint-disable-next-line
            { node.attrs.fill = this.stroke; }
            return node;
          }); // eslint-disable-line no-param-reassign
          return svgTree;
        })
        // Оставляем только внутренности тега <svg>
        .then(svgTree => render(svgTree.root.content))
        // Заменяем содержимое тега <svg> новым
        // eslint-disable-next-line no-return-assign
        .then(svgHtml => (this.$el.innerHTML = svgHtml))
        .then(() => this.generateReadyEvent && this.$emit('ready'))
        .catch((error) => {
          console.error('Ошибка при обновлении SVG', error);
          this.$emit('error', error);
        });
    },
  },
};
