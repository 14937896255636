import BrandLink from '../BrandLink/BrandLink.vue';
import BrandSvg from '../BrandSvg/BrandSvg.vue';

export default {
  name: 'SocialWidget',
  components: {
    BrandLink,
    BrandSvg,
  },
};
