import BrandSvg from '../BrandSvg/BrandSvg.vue';
import phoneMask from '../../helpers/phoneMask';

export default {
  name: 'BrandInput',
  inject: ['$validator'],
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {},
    type: {
      type: String,
      default: 'text',
    },
    required: {
      default: false,
    },
    disabled: {
      default: false,
    },
    validate: {
      default: false,
    },
    mask: {},
  },
  components: {
    BrandSvg,
  },
  data() {
    return {
      selfValue: this.value,
      focused: false,
      filled: false,
      rules: {
        name: {
          min: 3,
        },
        email: {
          email: true,
        },
        phone: {
          min: 10,
        },
      },
    };
  },
  mounted() {
    this.$watch('value', (value) => {
      this.selfValue = value;
    });
    // console.log('FIELDS_INPUT', this.$validator.fields);
    this.filled = !!this.selfValue;
    this.maskInput();
  },
  methods: {
    onInput() {
      this.$emit('input', this.selfValue);
      this.filled = !!this.selfValue;
    },
    onFocus() {
      this.$emit('focus');
      this.focused = true;
    },
    onBlur() {
      this.$emit('blur', this.selfValue);
      this.focused = false;
    },
    getFieldRules() {
      return {
        required: this.$props.required,
        ...this.rules[this.$props.name],
      };
    },
    maskInput() {
      /* eslint no-useless-escape: 0 */
      switch (this.$props.mask) {
        // Name mask
        // case 'name':
        //   this.$inputmask({
        //     mask: '*{3,128}',
        //     placeholder: ' ',
        //     showMaskOnHover: false,
        //     autoUnmask: true,
        //     greedy: false,
        //     definitions: {
        //       '*': {
        //         validator: '[А-Яа-яA-Za-z \-]',
        //         cardinality: 1,
        //       },
        //     },
        //   })
        //     .mask(this.$refs.inputField);
        //   break;

        // Phone mask
        case 'phone':
          new phoneMask(this.$refs.inputField);
          // this.$inputmask({
          //   // regex: '^\\+7\\(\[0-9]{3}\\) [0-9]{3}-[0-9]{2}-[0-9]{2}$',
          //   regex: '^\\+7\\(\[0-9]{3}\\) [0-9]{3}-[0-9]{2}-[0-9]{2}$',
          //   placeholder: '+7(___) ___-__-__',
          //   showMaskOnHover: false,
          //   autoUnmask: true,
          //   greedy: false,
          //   showMaskOnFocus: true,
          //   colorMask: true,
          // })
          //   .mask(this.$refs.inputField);
          break;

        // Email mask
        case 'email':
          this.$inputmask({
            mask: '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]',
            greedy: false,
            showMaskOnHover: false,
            definitions: {
              '*': {
                validator: '[0-9A-Za-z!#$%&\'*+/=?^_`{|}~\-]',
                cardinality: 1,
                casing: 'lower',
              },
            },
          })
            .mask(this.$refs.inputField);
          break;
        default:
          return true;
      }
      return true;
    },
  },
};
