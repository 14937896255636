import { mapActions, mapGetters } from 'vuex';
import api from '@/api/index';
import BrandButton from '../BrandButton/BrandButton.vue';

export default {
  name: 'Chooser',
  props: {
    target: String,
    picture: '',
  },
  components: {
    BrandButton,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    ...mapActions([
      'updateTotal',
      'removeOrders',
    ]),
    handleSelect() {
      if (this.isSelected) {
        const order = this.orders.items.find(item => item.name === this.target);
        if (typeof order !== 'undefined') {
          this.updateTotal(-(order.price * order.count));
          this.removeOrders([this.target]);
        }
        this.$store.commit('setModalOutput', {
          name: this.target,
          picture: '',
          price: 0,
        });
      } else {
        this.$store.commit('setModalData', this.data[this.target]);
        this.$store.commit('setModalTarget', this.target);
        this.$modal.show('brand-modal');
      }
    },
    async getCalcData() {
      this.data = await api.loadCalcData();
    },
  },
  computed: {
    ...mapGetters({
      orders: 'getOrders',
    }),
    isSelected: {
      get() {
        return this.picture.length;
      },
    },
  },
  mounted() {
    this.getCalcData();
  },
};
